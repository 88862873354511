// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-documents-jsx": () => import("./../../../src/pages/documents.jsx" /* webpackChunkName: "component---src-pages-documents-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-posts-old-jsx": () => import("./../../../src/pages/posts_old.jsx" /* webpackChunkName: "component---src-pages-posts-old-jsx" */),
  "component---src-pages-records-old-jsx": () => import("./../../../src/pages/records_old.jsx" /* webpackChunkName: "component---src-pages-records-old-jsx" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-record-js": () => import("./../../../src/templates/record.js" /* webpackChunkName: "component---src-templates-record-js" */),
  "component---src-templates-records-js": () => import("./../../../src/templates/records.js" /* webpackChunkName: "component---src-templates-records-js" */)
}

